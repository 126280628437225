import { Link } from "gatsby"

import React from "react"
const Footer = () => (
  <footer className="footer">
    <div className="content has-text-centered">
      <p>
        <span className="footer-item">Codestack BV</span>
        <span className="footer-item">-</span>
        <Link to="/privacy-policy" className="footer-item">
          Privacy policy
        </Link>
      </p>
    </div>
  </footer>
)

export default Footer
