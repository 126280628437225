import React from "react"
import Header from "./header"
import Footer from "./footer"
import "../styles/layout.scss"

class Layout extends React.Component {
  render() {
    return (
      <>
        <div className="container">
          <Header />
          {this.props.children}
        </div>
        <Footer></Footer>
      </>
    )
  }
}

export default Layout
