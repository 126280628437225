import React from "react"
const Hero = ({ title, subtitle, subtitleSize }) => (
  <section className="hero is-primary">
    <div className="hero-body">
      <div className="container">
        <h1 className="title">{title}</h1>
        <h2
          className={`subtitle ${
            subtitleSize ? `is-size-${subtitleSize}` : ""
          }`}
        >
          {subtitle}
        </h2>
      </div>
    </div>
  </section>
)

export default Hero
