import { Link } from "gatsby"
import React from "react"
import CodestackLogo from "../images/svg/codestack-logo-cropped.svg"
import EnableDarkThemeIcon from "../images/svg/brightness_4.svg"
import EnableLightThemeIcon from "../images/svg/wb_sunny.svg"
import { trySetTheme } from "../utils/local-storage"

class Header extends React.Component {
  state = {
    navActive: false,
    theme: null,
  }
  componentDidMount() {
    this.setState({ theme: window.__theme })
  }

  toggleTheme = e => {
    e.preventDefault()
    var toggledTheme = this.state.theme === "dark" ? "light" : "dark"
    let htmlElement = document.getElementsByTagName("html")[0]
    htmlElement.setAttribute("data-theme", toggledTheme)
    document.body.setAttribute("data-theme", toggledTheme)
    this.setState({ theme: toggledTheme })
    window.__theme = toggledTheme
    trySetTheme(toggledTheme)
  }

  render() {
    return (
      <header>
        <nav
          className="navbar is-transparent"
          role="navigation"
          aria-label="main navigation"
        >
          <div className="navbar-brand">
            <a className="navbar-item" aria-label="header logo" href="/">
              <CodestackLogo className="header-logo" />
            </a>

            <button
              className={`undecorated navbar-burger burger ${
                this.state.navActive ? "is-active" : ""
              }`}
              aria-label="menu"
              aria-expanded="false"
              data-target="nav-menu"
              onClick={() =>
                this.setState({ navActive: !this.state.navActive })
              }
              tabIndex="0"
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </button>
          </div>

          <div
            id="nav-menu"
            className={`navbar-menu ${this.state.navActive ? "is-active" : ""}`}
          >
            <div className="navbar-end">
              <Link to="/" className="navbar-item" activeClassName="active">
                Home
              </Link>
              <Link
                to="/about"
                className="navbar-item"
                activeClassName="active"
                partiallyActive={true}
              >
                About
              </Link>
              <Link
                to="/contact"
                className="navbar-item"
                activeClassName="active"
                partiallyActive={true}
              >
                Contact
              </Link>
              <Link
                to="/blog"
                className="navbar-item"
                activeClassName="active"
                partiallyActive={true}
              >
                Blog
              </Link>
              <button
                className="undecorated navbar-item theme-toggle-icon"
                onClick={this.toggleTheme}
                tabIndex="0"
                aria-label="theme toggle"
              >
                {this.state.theme !== "dark" && <EnableDarkThemeIcon />}
                {this.state.theme === "dark" && <EnableLightThemeIcon />}
              </button>
            </div>
          </div>
        </nav>
      </header>
    )
  }
}

export default Header
